import React, { useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Modal, Form } from 'react-bootstrap';
import { postRequest } from '../../utils/http-helper';
import { DOWNLOAD_DATA_URL } from '../../utils/url-helpers';
import { getCompanyId, getUserId } from '../../utils/localStorage';
import './download.css';

const Download = ({
  showDownloadModal,
  additionalFilters,
  onHideDownloadModal,
  downloadFilterData,
}) => {
  const [showSpinner, setSpinner] = useState(false);
  const [unitSelect, setUnitSelect] = useState({
    thresholdDistance: 0,
    thresholdUnit: '',
    overlappingPoly: false,
    polygonMinArea: 0,
    polygonMaxArea: 0,
    polygonAreaUnit: '',
  });

  const [errObj, setErrObj] = useState({
    thresholdDistanceUnitError: '',
    areaUnitError: '',
    minMaxAreaError: '',
  });

  const colourStyles = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
      border: isDisabled ? '' : '1px solid #e0e0e6',
      outline: isDisabled ? '' : '1px solid #e0e0e6',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: isDisabled ? '#f1f1f1' : '#ffffff',
        border: isDisabled ? '' : '1px solid #e0e0e6',
      },
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        backgroundColor: '#ffffff',
        color: '#606060',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: '#262261',
          color: '#ffffff',
        },
      };
    },
  };

  const thresholdDistanceOptions = [
    { value: 0, label: 'Centimetre' },
    { value: 1, label: 'Metre' },
    { value: 2, label: 'Kilometre' },
  ];

  const polygonAreaOptions = [
    { value: 0, label: 'Sq km' },
    { value: 1, label: 'Hectare' },
    { value: 2, label: 'Acre' },
  ];

  /**
   * ^ Sends a download request with the provided payload
   * @param {*} payload - The data payload for the download request
   * @returns The response from the download request
   */
  const sendDownloadRequest = async (payload) => {
    try {
      return await postRequest({
        url: DOWNLOAD_DATA_URL,
        data: payload,
        authHeader: true,
        responseType: 'blob',
      });
    } catch (error) {
      return error;
    }
  };

  /**
   * Clears the modal data and error messages
   */
  const modalClear = () => {
    setUnitSelect({
      ...unitSelect,
      thresholdDistance: '0',
      thresholdUnit: '',
      overlappingPoly: false,
      polygonMinArea: '0',
      polygonMaxArea: '0',
      polygonAreaUnit: '',
    });
    setErrObj({
      thresholdDistanceUnitError: '',
      areaUnitError: '',
      minMaxAreaError: '',
    });
  };

  /**
   * Handles the download button click event
   */
  const onDownloadClick = async () => {
    let errData = { ...errObj };
    if (
      Number(unitSelect?.thresholdDistance) !== 0 &&
      unitSelect.thresholdUnit?.label === undefined
    ) {
      errData.thresholdDistanceUnitError = 'Select Threshold Distance Unit';
    }
    if (Number(unitSelect.polygonMinArea) > Number(unitSelect.polygonMaxArea)) {
      errData.minMaxAreaError = 'Polygon Min Area Should not be greater than Max Area';
    }
    if (
      Number(unitSelect.polygonMaxArea) !== 0 &&
      unitSelect?.polygonAreaUnit?.label === undefined
    ) {
      errData.areaUnitError = 'Select Polygon Area Unit';
    }
    setErrObj(errData);

    if (
      errData.thresholdDistanceUnitError === '' &&
      errData.areaUnitError === '' &&
      errData.minMaxAreaError === ''
    ) {
      setSpinner(true);
      let payload = {
        form_id:
          typeof downloadFilterData.formId === 'object' && downloadFilterData.formId !== null
            ? downloadFilterData.formId.value.toString()
            : downloadFilterData.formId?.toString() || '',
        start_date: downloadFilterData.startDate
          ? moment(downloadFilterData.startDate, 'YYYY-MM-DD').format('YYYYMMDD')
          : '',
        end_date: downloadFilterData.endDate
          ? moment(downloadFilterData.endDate, 'YYYY-MM-DD').format('YYYYMMDD')
          : '',
        cluster_id:
          Array.isArray(downloadFilterData.clusterId) && downloadFilterData.clusterId.length > 0
            ? downloadFilterData.clusterId.map((item) => item.value.toString())
            : [],
        user_id:
          Array.isArray(downloadFilterData.userId) && downloadFilterData.userId.length > 0
            ? downloadFilterData.userId.map((item) => item.value.toString())
            : [],
        company_id: getCompanyId(),
        requesting_user: getUserId(),
        additional_filters: Object.fromEntries(
          Object.entries(additionalFilters).filter(([key, value]) => value !== null)
        ),
        threshold_distance: Number(unitSelect?.thresholdDistance),
        threshold_unit: unitSelect.thresholdUnit?.label,
        overlapping: unitSelect.overlappingPoly,
        polygon_area_min: Number(unitSelect.polygonMinArea),
        polygon_area_max: Number(unitSelect.polygonMaxArea),
        polygon_area_unit: unitSelect?.polygonAreaUnit?.label,
      };
      const { data, status, error } = await sendDownloadRequest(payload);

      if (status === 200) {
        if (data?.message === 'No data found') {
          setSpinner(false);
          modalClear();
          onHideDownloadModal();
          toast.success(data.message);
        } else {
          setSpinner(false);
          modalClear();
          const blob = new Blob([data], {
            type: 'application/xlsx',
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `data.xlsx`;
          a.click();

          onHideDownloadModal();
          toast.success('Data Downloaded Successfully');
        }
      } else if (status === 401) {
        toast.error(error?.response?.data.message);
      } else {
        setSpinner(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <Modal
      show={showDownloadModal}
      onHide={onHideDownloadModal}
      dialogClassName="custom-modal"
      centered>
      <Modal.Header closeButton className="modal-head">
        <Modal.Title>Download Data</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="box-area">
          <div className="box-content">
            <h1>Threshold Distance </h1>
          </div>
          <p>Maximum Distance allowed from the device location to centroid of polygon</p>
          <div className="text_area">
            <Form.Control
              type="number"
              className="text_area_distance"
              placeholder="Enter distance"
              value={unitSelect?.thresholdDistance}
              onChange={(e) => {
                setErrObj({ ...errObj, thresholdDistanceUnitError: '' });
                setUnitSelect({
                  ...unitSelect,
                  thresholdDistance: e?.target?.value,
                });
              }}
            />
            <div>
              <Select
                className="basic-single download-data"
                classNamePrefix="select"
                placeholder="Unit"
                options={thresholdDistanceOptions}
                styles={colourStyles}
                onChange={(e) => {
                  setErrObj({ ...errObj, thresholdDistanceUnitError: '' });
                  setUnitSelect({
                    ...unitSelect,
                    thresholdUnit: e,
                  });
                }}
                value={unitSelect?.thresholdUnit}
              />

              <label
                className={
                  errObj.thresholdDistanceUnitError ? 'error-text visible' : 'error-text hidden'
                }>
                {errObj.thresholdDistanceUnitError}
              </label>
            </div>
          </div>
        </div>

        <div className="box-area">
          <div className="box-content">
            <h1>Overlapping Polygons </h1>
            <Form.Check
              type="switch"
              className="switch_checkbox"
              id="custom-switch"
              checked={unitSelect?.overlappingPoly}
              onChange={() => {
                setUnitSelect({
                  ...unitSelect,
                  overlappingPoly: !unitSelect?.overlappingPoly,
                });
              }}
            />
          </div>
          <p>Remove overlapping polygons sharing common area or boundary.</p>
        </div>

        <div className="box-area">
          <div className="box-content">
            <h1>Polygon Area</h1>
          </div>
          <p>The largest acceptable size or extent of a polygon within specified constraints.</p>
          <div className="text_area">
            <div>
              <div className="text_area_area-container">
                <Form.Control
                  type="number"
                  className="text_area_area"
                  placeholder="Enter Min Area"
                  value={unitSelect?.polygonMinArea}
                  onChange={(e) => {
                    setUnitSelect({
                      ...unitSelect,
                      polygonMinArea: e?.target?.value,
                    });
                    setErrObj({ ...errObj, minMaxAreaError: '' });
                  }}
                />
                <Form.Control
                  type="number"
                  className="text_area_area"
                  placeholder="Enter Max Area"
                  value={unitSelect?.polygonMaxArea}
                  onChange={(e) => {
                    setUnitSelect({
                      ...unitSelect,
                      polygonMaxArea: e?.target?.value,
                    });
                    setErrObj({ ...errObj, minMaxAreaError: '' });
                  }}
                />
              </div>
              <label
                className={errObj.minMaxAreaError ? 'error-text visible' : 'error-text hidden'}>
                {errObj.minMaxAreaError}
              </label>
            </div>
            <div className="poly-select-container">
              <Select
                className="basic-single poly-select"
                classNamePrefix="select"
                placeholder="Unit"
                options={polygonAreaOptions}
                value={unitSelect.polygonAreaUnit}
                styles={colourStyles}
                onChange={(e) => {
                  setUnitSelect({
                    ...unitSelect,
                    polygonAreaUnit: e,
                  });
                  setErrObj({ ...errObj, areaUnitError: '' });
                }}
              />
              <label className={errObj.areaUnitError ? 'error-text visible' : 'error-text hidden'}>
                {errObj.areaUnitError}
              </label>
            </div>
          </div>
        </div>

        <div className="box-area">
          <div className="box-content">
            <h1>Download Format </h1>
          </div>
          <p>Choose your download format from below</p>
          <div className="check_area">
            <Form.Check type="checkbox" id="custom-checkbox" label="XLSX" checked />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="download-button" onClick={onDownloadClick}>
          Download
          {showSpinner === true && (
            <CircularProgress
              size={18}
              sx={{
                color: 'white',
                margin: '0px 10px',
              }}
              color="secondary"
            />
          )}
        </Button>
        &emsp;&nbsp;
        <Button
          variant="secondary"
          className="close-button"
          onClick={() => {
            onHideDownloadModal();
            modalClear();
          }}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Download;
